import {http} from "../plugins/http";

 let endpoint = '/'

export default {
    getAllDocuments:()=>{
        return http.get(endpoint+'movimentacao?usuario='+localStorage.getItem('id'),{
            headers:{'Authorization': 'Bearer '+localStorage.getItem('token')}
        })
    },
    getFilteredDocuments:(params)=>{
        let url = endpoint +'movimentacao/filter?usuario='+localStorage.getItem('id')+'&situacao='+params.situacao+'&dtInicio='+params.dtInicio+'&dtFim='+params.dtFim
        
        if(params.campo == 'Transportador')
            url+='&transportador='+params.valor
        if(params.campo == "Emitente")
            url+='&emitente='+params.valor
        if(params.campo == 'Destinatário')
            url+='&destinatario='+params.valor
        if(params.campo == 'Núm. documento')
            url+='&numeroDocumento='+params.valor
            if(params.campo == 'Conhecimento')
            url+='&idConhecimento='+params.valor
        return http.get(url,{
            headers:{'Authorization': 'Bearer '+localStorage.getItem('token')}
        })
    },
    getAllMdfeDocuments:()=>{
        return http.get(endpoint+'movimentacao/allMdfe?usuario='+localStorage.getItem('id'),{
            headers:{'Authorization': 'Bearer '+localStorage.getItem('token')}
        })
    },
    getMdfeFilteredDocuments:(params)=>{
        let url = endpoint +'movimentacao/filterMdfe?usuario='+localStorage.getItem('id')+'&situacao='+params.situacao+'&dtInicio='+params.dtInicio+'&dtFim='+params.dtFim
        
        if(params.campo == 'Transportador')
            url+='&transportador='+params.valor
        if(params.campo == 'Núm. documento')
            url+='&numeroDocumento='+params.valor
        if(params.campo == 'Placa')
            url+='&placa='+params.valor
        return http.get(url,{
            headers:{'Authorization': 'Bearer '+localStorage.getItem('token')}
        })
    },
    getExportFilteredDocuments:(params)=>{
        let url = endpoint +'movimentacao/filterExport?usuario='+localStorage.getItem('id')+'&tipo='+params.tipo+'&dtInicio='+params.dtInicio+'&dtFim='+params.dtFim
        
        if(params.campo == 'Transportador')
            url+='&transportador='+params.valor
        if(params.campo == 'Núm. documento')
            url+='&numeroDocumento='+params.valor
        return http.get(url,{
            headers:{'Authorization': 'Bearer '+localStorage.getItem('token')}
        })
    },
    getExportFilteredDocumentsCsv:(params)=>{
        let url = endpoint +'movimentacao/filterExportCsv?usuario='+localStorage.getItem('id')+'&situacao='+params.situacao+'&tipo='+params.tipo+'&dtInicio='+params.dtInicio+'&dtFim='+params.dtFim
        
        if(params.campo == 'Transportador')
            url+='&transportador='+params.valor
        if(params.campo == 'Núm. documento')
            url+='&numeroDocumento='+params.valor
        return http.get(url,{
            headers:{'Authorization': 'Bearer '+localStorage.getItem('token')}
        })
    },
    
    download:(path,tipo)=>{
        return http.get(endpoint+'v1/documentos?caminhoDocumento='+path+"&tipo="+tipo,{
            headers:{'Authorization': 'Bearer '+localStorage.getItem('token')}
        })
    },
    getAllParticipantes:()=>{
        return http.get(endpoint+'participantes?usuario='+localStorage.getItem('id'),{
            headers:{'Authorization': 'Bearer '+localStorage.getItem('token')}
        })
    },

    getAllFaturas:()=>{
        return http.get('https://api.cte.workinnovation.com.br/v1/cte/faturas/pendentesUsuario?idUsuario='+localStorage.getItem('id') + '&status=1',{
            headers:{'Authorization': 'Bearer '+localStorage.getItem('token')}
        })
    }
}
